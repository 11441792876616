nz-card.no-padding > .ant-card-body {
  padding: 1px 0 0;
}
.no-padding .ant-modal-body {
  padding: 0;
}
.no-padding .ant-drawer-body {
  padding: 0;
}
.no-padding .ant-popover-inner-content {
  padding: 0;
}
nz-card.no-padding .ant-card-body {
  padding: 1px 0 0;
}
nz-comment.no-padding .ant-comment-inner {
  padding: 0;
}
nz-card.no-header-padding .ant-card-head,
nz-card.no-header-padding .ant-card-head-title {
  padding: 0;
}
nz-collapse-panel.ant-collapse-item.no-header-padding .ant-collapse-header {
  padding: 0;
}
nz-collapse-panel.ant-collapse-item.no-header-padding .ant-collapse-header > div:first-child {
  @apply h-full;
}
nz-collapse-panel.no-padding .ant-collapse-content-box {
  padding: 0;
}
.ant-popover.no-padding .ant-popover-inner-content {
  padding: 0;
}
.ant-modal.no-padding .ant-modal-body {
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3Byb2plY3QtaHViL3Byb2plY3QtaHViL2FwcHMvZnJvbnRlbmQvc3JjL3N0eWxlcy90aGVtZS5sZXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLE9BQU8sV0FBWTtFQUNqQixnQkFBQTs7QUFHRixXQUNFO0VBQ0UsVUFBQTs7QUFGSixXQUtFO0VBQ0UsVUFBQTs7QUFOSixXQVNFO0VBQ0UsVUFBQTs7QUFJSixPQUFPLFdBQVk7RUFDakIsZ0JBQUE7O0FBR0YsVUFBVSxXQUFZO0VBQ3BCLFVBQUE7O0FBR0YsT0FBTyxrQkFDTDtBQURGLE9BQU8sa0JBQ1c7RUFDZCxVQUFBOztBQUlKLGlCQUFpQixrQkFBa0Isa0JBQ2pDO0VBQ0UsVUFBQTs7QUFGSixpQkFBaUIsa0JBQWtCLGtCQUNqQyxxQkFHRSxNQUFLO0VBQ0w7O0FBS0osaUJBQWlCLFdBQVk7RUFDM0IsVUFBQTs7QUFHRixZQUFZLFdBQVk7RUFDdEIsVUFBQTs7QUFHRixVQUFVLFdBQVk7RUFDcEIsVUFBQSIsInNvdXJjZXNDb250ZW50IjpbIm56LWNhcmQubm8tcGFkZGluZyA+IC5hbnQtY2FyZC1ib2R5IHtcbiAgcGFkZGluZzogMXB4IDAgMDtcbn1cblxuLm5vLXBhZGRpbmcgPiB7XG4gIC5hbnQtbW9kYWwtYm9keSB7XG4gICAgcGFkZGluZzogMDtcbiAgfVxuXG4gIC5hbnQtZHJhd2VyLWJvZHkge1xuICAgIHBhZGRpbmc6IDA7XG4gIH1cblxuICAuYW50LXBvcG92ZXItaW5uZXItY29udGVudCB7XG4gICAgcGFkZGluZzogMDtcbiAgfVxufVxuXG5uei1jYXJkLm5vLXBhZGRpbmcgLmFudC1jYXJkLWJvZHkge1xuICBwYWRkaW5nOiAxcHggMCAwO1xufVxuXG5uei1jb21tZW50Lm5vLXBhZGRpbmcgLmFudC1jb21tZW50LWlubmVyIHtcbiAgcGFkZGluZzogMDtcbn1cblxubnotY2FyZC5uby1oZWFkZXItcGFkZGluZyB7XG4gIC5hbnQtY2FyZC1oZWFkLCAuYW50LWNhcmQtaGVhZC10aXRsZSB7XG4gICAgcGFkZGluZzogMDtcbiAgfVxufVxuXG5uei1jb2xsYXBzZS1wYW5lbC5hbnQtY29sbGFwc2UtaXRlbS5uby1oZWFkZXItcGFkZGluZyB7XG4gIC5hbnQtY29sbGFwc2UtaGVhZGVyIHtcbiAgICBwYWRkaW5nOiAwO1xuXG4gICAgPiBkaXY6Zmlyc3QtY2hpbGQge1xuICAgIEBhcHBseSBoLWZ1bGw7XG4gICAgfVxuICB9XG59XG5cbm56LWNvbGxhcHNlLXBhbmVsLm5vLXBhZGRpbmcgLmFudC1jb2xsYXBzZS1jb250ZW50LWJveCB7XG4gIHBhZGRpbmc6IDA7XG59XG5cbi5hbnQtcG9wb3Zlci5uby1wYWRkaW5nIC5hbnQtcG9wb3Zlci1pbm5lci1jb250ZW50IHtcbiAgcGFkZGluZzogMDtcbn1cblxuLmFudC1tb2RhbC5uby1wYWRkaW5nIC5hbnQtbW9kYWwtYm9keSB7XG4gIHBhZGRpbmc6IDA7XG59XG4iXX0= */