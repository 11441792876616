@keyframes loader-animation {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

.page-loader-animation div {
  box-sizing: border-box !important
}

.page-loader-animation > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid;
  border-color: #1f588b transparent #1f588b transparent;
  animation: loader-animation 1s linear infinite;
}

.page-loader-animation > div:nth-child(2) {
  border-color: transparent
}

.page-loader-animation > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.page-loader-animation > div:nth-child(2) div:before, .page-loader-animation > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #1f588b;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #1f588b;
}

.page-loader-animation > div:nth-child(2) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #1f588b;
}

.page-loader {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.page-loader-animation {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.page-loader-animation div {
  box-sizing: content-box;
}
