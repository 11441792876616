nz-card.no-padding > .ant-card-body {
  padding: 1px 0 0;
}

.no-padding > {
  .ant-modal-body {
    padding: 0;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}

nz-card.no-padding .ant-card-body {
  padding: 1px 0 0;
}

nz-comment.no-padding .ant-comment-inner {
  padding: 0;
}

nz-card.no-header-padding {
  .ant-card-head, .ant-card-head-title {
    padding: 0;
  }
}

nz-collapse-panel.ant-collapse-item.no-header-padding {
  .ant-collapse-header {
    padding: 0;

    > div:first-child {
    @apply h-full;
    }
  }
}

nz-collapse-panel.no-padding .ant-collapse-content-box {
  padding: 0;
}

.ant-popover.no-padding .ant-popover-inner-content {
  padding: 0;
}

.ant-modal.no-padding .ant-modal-body {
  padding: 0;
}
